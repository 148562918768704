import api from '..';
import getFormData from './getFormData';
import { ENTER_MANUALLY } from '../../payments/pages/Withdraw/constants';
import { DEPOSIT_METHODS } from 'modules/paymentManagement/constants';

export const paymentsDepositIsignthisRequest = (payload) => {
  return api.post('/payments/deposit-isignthis', payload);
};

export const paymentsDepositPaymentAsiaRequest = (payload) => {
  return api.post('/payments/deposit-payment-asia', payload);
};

export const paymentsDepositPraxisRequest = (payload) => {
  return api.post('/payments/deposit-praxis', payload);
};

export const paymentsDepositFinmoRequest = ({ accountId }) => {
  return api.post('/payments/deposit-finmo', { accountId });
};

export const paymentsDepositMonoovaRequest = ({ accountId }) => {
  return api.post('/payments/deposit-monoova', { accountId });
};

export const paymentsDepositSmartwayRequest = (payload) => {
  const formData = getFormData(payload);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post('/payments/deposit-smartway', formData, headers);
};

export const paymentsDepositScbRequest = (payload) => {
  return api.post('/payments/deposit-scb', payload);
};

export const addDepositRequest = ({ _id, amount, method, receiptNumber }) => {
  const params = { _id, amount, method, receiptNumber };
  if (method !== DEPOSIT_METHODS.manualDeposit) {
    delete params.receiptNumber;
  }
  return api.post('/payments/deposit-manual', params);
};

export const addDepositManuallyRequest = ({ _id, amount, method, receiptNumber }) => {
  return addDepositRequest({ _id, amount, method, receiptNumber });
};

export const getBankInfoRequest = () => {
  return api.get('/payments/bank-info');
};

export const paymentsWithdrawBankwireRequest = (payload) => {
  const formData = getFormData(payload);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post('/payments/withdraw-bankwire', formData, headers);
};

export const paymentsWithdrawHyperwalletRequest = (payload) => {
  const formData = getFormData(payload);

  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post('/payments/withdraw-hyperwallet', formData, headers);
};

export const paymentsWithdrawLocalBankwireRequest = (payload) => {
  delete payload.convertedAmount;
  const formData = getFormData(payload);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post('/payments/withdraw-local-bankwire', formData, headers);
};

export const paymentsWithdrawUnionpayBankwireRequest = (payload) => {
  delete payload.convertedAmount;
  payload.bankName = payload.bankName.value;
  const formData = getFormData(payload);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post('/payments/withdraw-unionpay-bankwire', formData, headers);
};

export const paymentsWithdrawInternationalRequest = (payload) => {
  const formData = getFormData(payload);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post('/payments/withdraw-bankwire', formData, headers);
};

export const paymentsWithdrawHyperwalletInternationalRequest = (payload) => {
  const formData = getFormData(payload);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post('/payments/withdraw-hyperwallet', formData, headers);
};

export const paymentsWithdrawVisaRequest = (payload) => {
  if (payload.cardName === ENTER_MANUALLY) {
    return api.post('/payments/withdraw-visa-custom', payload);
  }
  delete payload.last4Digits;

  return api.post('/payments/withdraw-visa', payload);
};

export const paymentsWithdrawVisaCustomCardRequest = (payload) => {
  return api.post('/payments/withdraw-visa-custom', payload);
};

export const getPaymentOptionsRequest = (payload) => {
  return api.get('/payments/payment-options', { params: payload });
};

export const searchDepositHistoryRequest = (payload) => {
  return api.get('/payments/history-deposit', { params: payload });
};

export const searchWithdrawHistoryRequest = (payload) => {
  return api.get('/payments/history-withdraw', { params: payload });
};

export const searchTransferHistoryRequest = (payload) => {
  return api.get('/payments/history-transfer', { params: payload });
};

export const getPendingWithdrawalRequest = (payload) => {
  const cleanedPayload = { ...payload };
  delete cleanedPayload.persistStoreData;
  return api.get('/payments/withdraw-requests', { params: cleanedPayload });
};

export const getPendingSmartwayRequest = (payload) => {
  const cleanedPayload = { ...payload };
  delete cleanedPayload.persistStoreData;
  return api.get('/payments/smartway-requests', { params: cleanedPayload });
};

export const approveSmartwayRequest = (payload) => {
  return api.patch('/payments/approve-smartway', payload);
};

export const declineSmartwayRequest = (payload) => {
  return api.patch('/payments/decline-smartway', payload);
};

export const loadPaymentDetailsRequest = (payload) => {
  return api.get('/payments/withdraw-details', { params: payload });
};

export const approveWithdrawalRequest = (payload) => {
  return api.patch('/payments/approve-withdraw', payload);
};

export const manualApproveWithdrawalRequest = (payload) => {
  return api.patch('/payments/manual-approve-withdraw', payload);
};

export const approveWithdrawalViewDetailsRequest = (payload) => {
  return api.patch('/payments/approve-withdraw', payload);
};

export const declineWithdrawalViewDetailsRequest = (payload) => {
  return api.patch('/payments/decline-withdraw', payload);
};

export const declineWithdrawalRequest = (payload) => {
  return api.patch('/payments/decline-withdraw', payload);
};

export const getPaymentsHistoryRequest = (payload) => {
  return api.get('/payments/payments-history', { params: payload });
};

export const getPaymentsPendingHistoryRequest = (payload) => {
  return api.get('/payments/payments-pending-history', { params: payload });
};

export const clientConfirmWithdrawRequest = (payload) => {
  return api.patch('/payments/client-confirm-withdraw', payload);
};

export const getConversionRateDepositRequest = (payload) => {
  return api.get('/payments/exchangerate', { params: payload });
};

export const updatePaymentRequest = (payload) => {
  return api.post('/payments/update', payload);
};

export const skrillWithdrawalRequest = (payload) => {
  return api.post('/payments/withdraw-skrill', payload);
};

export const netellerWithdrawalRequest = (payload) => {
  return api.post('/payments/withdraw-neteller', payload);
};

export const paypalWithdrawalRequest = (payload) => {
  return api.post('/payments/withdraw-paypal', payload);
};

export const payidWithdrawalRequest = (payload) => {
  delete payload.convertedAmount;
  return api.post('/payments/withdraw-payid', payload);
};

export const perfectMoneyWithdrawalRequest = (payload) => {
  delete payload.convertedAmount;
  return api.post('/payments/withdraw-perfect-money', payload);
};

export const interacETransferOutboundWithdrawalRequest = (payload) => {
  return api.post('/payments/withdraw-interac-e-transfer-outbound', payload);
};

export const jetonWithdrawalRequest = (payload) => {
  return api.post('/payments/withdraw-jeton', payload);
};

export const sendPartnerTransferRequest = (payload) => {
  return api.post('/payments/partner-transfer', payload);
};

export const getPartnerTransferHistoryRequest = () => {
  return api.get('/payments/history-partner-transfer');
};

export const getPendingPartnerTransferRequest = () => {
  return api.get('/payments/partner-transfer-management');
};

export const approvePendingPartnerTransferRequest = (payload) => {
  return api.post('/payments/approve-partner-transfer', payload);
};

export const declinePendingPartnerTransferRequest = (payload) => {
  return api.post('/payments/decline-partner-transfer', payload);
};

export const getWithdrawalCommentsRequest = (payload) => {
  return api.get('/payments/comments', { params: payload });
};

export const addWithdrawalCommentsRequest = (payload) => {
  return api.post('/payments/comments', payload);
};

export const getPaymentCommentsRequest = (payload) => {
  return api.get(`/payments/payment-comments`, { params: payload });
};

export const addPaymentCommentsRequest = (payload) => {
  return api.post(`/payments/payment-comments`, payload);
};

export const processWithdrawalRequest = (payload) => {
  return api.post('/payments/process', payload);
};

export const approvalWithdrawalRequest = (payload) => {
  return api.post('/payments/approval', payload);
};

export const generatePendingWithdrawalRequest = () => {
  return api.get('/payments/generate-withdrawals');
};

export const generatePaypalWithdrawalsRequest = (payload) => {
  return api.get('/payments/generate-withdrawals/paypal', { params: payload });
};

export const generatePlusDebitWithdrawalsRequest = (payload) => {
  return api.post('/payments/generate-withdrawals/plusdebit_withdrawals', payload);
};

export const generateLuckyMarioWithdrawalsRequest = (payload) => {
  return api.post('/payments/generate-withdrawals/luckymario_withdrawals', payload);
};

export const getThaiBanksRequest = (payload) => {
  return api.get('/payments/thai-banks', { params: payload });
};

export const paymentsWithdrawThunderxpayRequest = (payload) => {
  return api.post('/payments/withdraw-thunderxpay', payload);
};

export const processOperationsRequest = (payload) => {
  return api.post(`/payments/bulk-operations-upload`, payload);
};

export const paymentsWithdrawFinraxRequest = (payload) => {
  return api.post(`/payments/withdraw-crypto`, payload);
};

export const validateAddressRequest = (payload) => {
  return api.post(`/payments/crypto/validate-address`, payload);
};

export const feeRequest = (payload) => {
  return api.get(`/payments/crypto/fee`, { params: payload });
};

export const paymentsWithdrawAstropayRequest = (payload) => {
  return api.post(`/payments/withdraw-astropay`, payload);
};

export const paymentsWithdrawDragonpayRequest = (payload) => {
  return api.post(`/payments/withdraw-dragonpay`, payload);
};

export const paymentsWithdrawDokupayRequest = (payload) => {
  return api.post(`/payments/withdraw-dokupay`, payload);
};

export const getAvailablePspRequest = (payload) => {
  return api.get('/payments/available-psp', { params: payload });
};

export const getAvailableLocalBanksRequest = (payload) => {
  return api.get('/payments/available-local-banks', { params: payload });
};

export const getPaypalRefundsRequest = (payload) => {
  return api.get('/payments/paypal-payments', { params: payload });
};

export const paypalRefundRequest = (payload) => {
  return api.post(`/payments/paypal-refund`, payload);
};

export const getPaymentsPermissionsRequest = () => {
  return api.get(`/payments/permissions`);
};

export const paymentsGetDay1UserRequest = () => {
  return api.get(`/payments/crypto-day1/user`);
};

export const paymentsCreateDay1UserRequest = () => {
  return api.post(`/payments/crypto-day1/user`);
};

export const getCryptoDay1WalletsRequest = (payload) => {
  return api.get('/payments/crypto-day1/get-wallets', payload);
};

export const getCryptoDay1AssetsRequest = () => {
  return api.get('/payments/crypto-day1/assets');
};

export const getCryptoDay1SpotPriceRequest = (payload) => {
  const { cryptoCurrency, accountCurrency } = payload;
  return api.post('/payments/crypto-day1/spot-price', { cryptoCurrency, accountCurrency });
};

export const generateCryptoDay1WalletRequest = (payload) => {
  return api.post('/payments/crypto-day1/generate-wallet', payload);
};

export const updateCryptoDay1WalletRequest = (payload) => {
  return api.patch('/payments/crypto-day1/update-wallet', payload);
};

export const unableToRefundRequest = (payload) => {
  const formData = getFormData(payload);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.post(`/payments/paypal/unable-refund-request`, formData, headers);
};

export const declineUnablePaypalRefundRequest = ({ id, message }) => {
  return api.post(`/payments/paypal/unable-refund-request/${id}/decline`, { message });
};

export const approveUnablePaypalRefundRequest = ({ id, emails }) => {
  return api.post(`/payments/paypal/unable-refund-request/${id}/approve`, { emails });
};

export const paymentsWithdrawDay1ValidateRequest = (payload) => {
  return api.post(`/payments/crypto-day1/wallet/validate`, payload);
};

export const paymentsWithdrawDay1FeeRequest = (payload) => {
  return api.post(`/payments/crypto-day1/withdraw/fee`, payload);
};

export const paymentsWithdrawDay1LimitRequest = (payload) => {
  return api.post(`/payments/crypto-day1/withdraw/limit`, payload);
};

export const paymentsWithdrawDay1Request = (payload) => {
  return api.post(`/payments/crypto-day1/withdraw`, payload);
};

export const paymentsWithdrawDisplayedMethodsRequest = (payload) => {
  return api.post(`/payments/log-displayed-methods`, payload);
};
